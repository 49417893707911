import React, { useState, useEffect } from "react";
import { Box} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import "./Debt.css";
import api from "../../api/tokenCode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { notifySuccess } from "../../constant/toast";

const checkboxes = [
  { id: 1, title: "Vehicle" },
  { id: 2, title: "Student Loan" },
  { id: 3, title: "Mortgage" },
  { id: 4, title: "Personal Loan" },
  { id: 5, title: "Credit Card" },
];

const InputField = ({ maxLength,placeholder, register, error, type = "text", style }) => (
  <div className="input-field-container">
    <input
      type={type === "number" ? "text" : type} // Use "text" for number input to handle cursor position
      className="input-field"
      maxLength={maxLength}
      onInput={(e) => {
        if (type === "text") {
          // let inputValue = e.target.value; // Allow only letters and spaces
          // inputValue = inputValue.length > 1 ? inputValue.trimStart() : inputValue; // Trim leading spaces only if more than one character
          // e.target.value = inputValue.charAt(0).toUpperCase() + inputValue.slice(1); // Capitalize the first character
        } else if (type === "number") {
          let inputValue = e.target.value;

          // Preserve cursor position
          const cursorPosition = e.target.selectionStart;

          // Remove non-numeric characters except the first decimal point
          inputValue = inputValue.replace(/[^0-9.]/g, ""); // Allow only numbers and decimal

          // Ensure only one decimal point is allowed
          const hasDecimal = inputValue.includes(".");
          if (hasDecimal && inputValue.split(".").length > 2) {
            // Remove any additional decimals beyond the first
            inputValue = inputValue.replace(/(\..*?)\./g, "$1");
          }

          // Update the input value and reset cursor
          e.target.value = inputValue;
          e.target.setSelectionRange(cursorPosition, cursorPosition);
        }
      }}
      onBlur={(e) => {
        if (type === "number") {
          // Ensure value is parsed as a float or empty if invalid
          e.target.value = e.target.value ? parseFloat(e.target.value) || "" : "";
        }
      }}
      placeholder={placeholder}
      {...register}
      style={style}
    />

    {error && <p className="error-message">{error.message}</p>}
  </div>
);

const UpdateDebt = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const [debtData, setDebtData] = useState(null);
  const [savedDebt, setSavedDebt] = useState(null);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null); // State for selected checkbox
  const clientData = JSON.parse(localStorage.getItem("client"));
  const userId = clientData?.user_id || "";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDebtData = async () => {
      try {
        const response = await api.get(
          `/debt/getSingleDebtDetails/${userId}/${id}`
        );
        setDebtData(response.data.debtData);
        populateForm(response.data.debtData);
      } catch (error) {
        console.error("Error fetching debt data:", error);
      }
    };

    fetchDebtData();
  }, [id]);

  const populateForm = (data) => {
    if (!data || data.length === 0) {
      console.error("No data available to populate the form.");
      return;
    }

    const debt = data[0];

    // Set the values from the debt data to the form fields
    setValue("name", debt.name || "");
    setValue("loanAmount", debt.loanAmount || "");
    setValue("apr", debt.apr || "");
    setValue("currentPayment", debt.currentPayment || "");
    setValue("minimumPayment", debt.minimumPayment || "");
    setValue("startDate", debt.startDate || "");
    setValue("term", Number(debt.term) || "");
    setValue("other", debt.other === "Yes");
    setValue("otherDescription", debt.otherStr !== "None" ? debt.otherStr : "");

    const selectedCheckbox = checkboxes.find(
      (checkbox) => checkbox.title === debt.type);
    if (selectedCheckbox) {
      setSelectedCheckbox(selectedCheckbox.id);
      setValue(`checkboxes.${selectedCheckbox.id}`, true);
    }
  };

  const handleCheckboxChange = (id) => {
    // Uncheck all checkboxes except the one with the given id
    checkboxes.forEach(({ id: checkboxId }) => {
      if (checkboxId !== id) {
        setValue(`checkboxes.${checkboxId}`, false);
      }
    });

    setSelectedCheckbox(id); // Update the selected checkbox id
    setValue(`checkboxes.${id}`, true);
  };

  const handleRowClick = () => {
    navigate(`/details/${clientData.id}`);
  };

  const onSubmit = async (formData) => {
    console.log(formData,"test");
    
    const selectedType =
      checkboxes.find(({ id }) => formData.checkboxes?.[id])?.title ||
      (formData.other ? "Other" : "");

    const updatedDebtData = [
      {
        otherStr: formData.other ? formData.otherDescription || "None" : "None",
        name: formData.name || "",
        loanAmount: formData.loanAmount || "",
        apr: formData.apr || "",
        currentPayment: formData.currentPayment || "",
        minimumPayment: formData.minimumPayment || "",
        startDate: formData.startDate || "",
        term: Number(formData.term) || "",
        type: selectedType,
      },
    ];

    try {
      const response = await api.put(`/debt/updateDebt/${id}`, {
        loan: updatedDebtData,
      });
      console.log(response,"response");
      
      setSavedDebt(response.data);
      notifySuccess("Debt Updated successfully!");
      setTimeout(() => {
        navigate(`/details/${clientData.id}`);
      }, 2000);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSidenav data={savedDebt} />
      <Box
        component="main"
        className="debtPadding"
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          // overflowY: "auto",
        }}
      >
        <div className="main-container">
          <div>
            <h1 className="debt-name">UPDATE DEBT</h1>
            <button
              type="button"
              className="back-button"
              onClick={handleRowClick}
            >
              BACK
            </button>
          </div>
          <div className="container">
            <p className="">UPDATE LOAN</p>
          </div>
          <hr className="hr-tag" />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="checkbox-list">
              {checkboxes.map(({ id, title }) => (
                <label key={id} className="checkbox-item">
                  <input
                    type="checkbox"
                    className="custom-checkbox"
                    {...register(`checkboxes.${id}`)}
                    onChange={() => handleCheckboxChange(id)}
                  />
                  {title}
                </label>
              ))}
              <label className="checkbox-item">
                <input
                  type="checkbox"
                  className="custom-checkbox"
                  {...register("other")}
                />
                Other:
                <Controller
                  name="otherDescription"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      onInput={(e) =>
                      (e.target.value = e.target.value.replace(
                        /[^A-Za-z]/g,
                        ""
                      ))
                      }
                      placeholder="Describe"
                      className="input-field"
                      {...field}
                    />
                  )}
                />
              </label>
            </div>

            <div className="input-container">
              {/* Lender: Text input */}
              <InputField
                placeholder="Lender"
                register={register("name", {
                  required: "* Lender is required",
                })}
                error={errors.name}
              />

              {/* Loan Amount: Numeric input, allows only numbers */}
              <InputField
                type="number" // Numeric input
                placeholder="Loan Amount"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                } // Ensure only digits
                register={register("loanAmount", {
                  required: "* Loan Amt is required",
                })}
                error={errors.loanAmount}
              />

              {/* APR %: Numeric input */}
              <InputField
                type="number" // Numeric input
                placeholder="APR %"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                } // Ensure only digits
                register={register("apr", {
                  required: "* APR is required",
                })}
                error={errors.apr}
              />

              {/* Current Payment: Numeric input */}
              <InputField
                type="number" // Numeric input
                placeholder="Current Pymt"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                } // Ensure only digits
                register={register("currentPayment", {
                  required: "* Curnt Pymt is required",
                })}
                error={errors.currentPayment}
              />

              {/* Minimum Payment: Numeric input */}
              {/* <InputField
                type="number" // Numeric input
                placeholder="Min Pymt"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                } // Ensure only digits
                register={register("minimumPayment", {
                  required: "* Min Pymt is required",
                })}
                error={errors.minimumPayment}
              /> */}

              <InputField
                placeholder="Years"
                className="lenderFields"
                register={register("term", {
                  required: "* End Year is required",
                })}
                error={errors.term}
                maxLength={4}
              />

              <div>
                <button type="submit" className="submit-button">
                  UPDATE
                </button>
              </div>
            </div>
          </form>
        </div>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default UpdateDebt;
