import React, { useMemo, useEffect, useState } from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
  Area,
  ReferenceLine,
  CartesianGrid,
  Tooltip,
} from "recharts";
import api from "../../api/tokenCode";
import { useLocation } from "react-router-dom";
import "./Graph.css";

const formatInteger = (value) => Math.round(value);

const CreateHlv = ({ data }) => {
  const clientData = JSON.parse(localStorage.getItem("client"));
  // Set initial domain for XAxis
  const userId = clientData?.user_id || "";
  const location = useLocation();

  const retireAge = clientData?.futurePlanning?.areYouGoingToRetire?.age;

  const [debtData, setDebtData] = useState(location.state?.debtData || data);
  const [termData, setTermData] = useState(data || location.state?.termData);
  const [wholeData, setWholeData] = useState(data || location.state?.wholeData);
  const [liabilityData, setLiabilityData] = useState([]);
  // const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1.1);
  // const [zoomLevel, setZoomLevel] = useState(2.4);
  const birth_date = new Date(clientData?.clientInfo.personal?.birthDay);
  const current_date = new Date();
  const ageDiffMs = current_date.getTime() - birth_date.getTime();
  const ageDate = new Date(ageDiffMs);
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  const [minAge, setMinAge] = useState(null);
  const [maxAge, setMaxAge] = useState(null);
  
  const incomeValues = clientData?.assetsData?.activeIncome;


  const start = age;
  const end = 100;
  const len = (end - start) * 100;

  const ticks = useMemo(() => {
    const step = zoomLevel > 2 ? 1 : zoomLevel > 1.5 ? 2 : 5;
    const t = [];
    for (let i = start; i <= end; i += step) {
      t.push(i);
    }
    if (end % step !== 0) {
      t.push(end);
    }
    return t;
  }, [start, end, zoomLevel]);

  // const filledData = useMemo(() => {
  //   const result = [];
  //   for (let i = start; i <= end; i++) {
  //     const existing = liabilityData.find((item) => item.age === i);
  //     result.push(existing || { age: i, value: 0 });
  //   }
  //   return result;
  // }, [liabilityData, start, end]);


  const periods = Array.from(
    { length: len },
    (value, index) => start + index * 0.01
  );

  const [domain, setDomain] = useState([ticks[0], ticks.slice(-1)[0]]);

  const calculateIncome = () => {
    const activeIncome = clientData?.assetsData?.activeIncome?.reduce((total, income) => {
      return total + (income.annual || 0);
    }, 0) / 1000;

    const data = [];
    periods.forEach((element) => {
      data.push({
        age: element,
        Income: activeIncome,
      });
    });
    return data;
  };

  const incomeData = useMemo(calculateIncome, [
    periods,
    clientData?.assetsData?.activeIncome,
  ]);

  const maxIncome = Math.ceil(
    Math.max(...incomeData.map((d) => d.Income), 0) / 10
  ) * 10; // Round to the nearest 10

  const height = "28%";
  const incomeHeight = "8%";
  const cdHeight = "14%"
  const surplusHeight = "35%"

  const formatXAxis = (tickItem) => {
    return Math.round(tickItem).toString();
  };

  const calculateLiabilityTerms = (debts, startYear, endYear) => {
    const monthsInYear = 12;
    const terms = [];

    // Generate timeline in fractional years (e.g., 2025.08 for Feb 2025)
    for (let year = startYear; year <= endYear; year++) {
      for (let month = 0; month < monthsInYear; month++) {
        terms.push(year + month / monthsInYear);
      }
    }

    // Store amortization details
    const debtAmortizations = [];

    if (Array.isArray(debts)) {
      debts.forEach((d) => {
        let remaining = d.loanAmount;
        const amortization = [];

        // Calculate number of months from start to end year
        const totalMonths = (d.endYear - d.startYear) * 12;

        for (let i = 0; i < totalMonths; i++) {
          const interest = (remaining * (d.apr / 100)) / 12;
          remaining = remaining - (d.currentPayment - interest);
          if (remaining < 0) remaining = 0;
          amortization.push(remaining);
        }

        debtAmortizations.push({
          debt: d,
          amortization: amortization,
          start: d.startYear
        });
      });
    }

    // Map calculated amortizations into term-based data
    const data = terms.map((age, index) => {
      const entry = { age };

      debtAmortizations.forEach((d) => {
        // Assign debt values per age in years
        entry[d.debt.name] = index < d.amortization.length ? -d.amortization[index] / 1000 : 0;
      });

      return entry;
    });

    return data;
  };


  const calculateLiability = (debts, startYear, endYear) => {
    const termData = calculateLiabilityTerms(debts, startYear, endYear);

    return termData.map(entry => ({
      ...entry,
      age: Math.round(entry.age) // Ensure clean, rounded age values
    }));
  };


  const calculateWholeSlopes = (wholes, birth_date) => {

    const slopeCalculation = (y1, y2, x1, x2) => {
      const rise = y2 - y1;
      const run = x2 - x1;
      const m = rise / run;
      const b = y1 - (x1 * rise) / run;
      return { m, b };
    };

    if (!Array.isArray(wholes)) {
      console.error("wholes is not an array:", wholes);
      return;
    }

    wholes.forEach((w) => {
      if (!w) {
        console.error(
          "wholeLifeCoverage is undefined for an entry in wholes:",
          w
        );
        return;
      }

      const start = Date.parse(w.startDate);
      const slopes = {};

      // 0-1
      slopes[1] = slopeCalculation(w["year1"], w["year1"], 0, 1);

      // 1-5
      slopes[5] = slopeCalculation(w["year1"], w["year5"], 1, 5);
      // 5-10
      slopes[10] = slopeCalculation(w["year5"], w["year10"], 5, 10);
      // 10 - 20
      slopes[20] = slopeCalculation(w["year10"], w["year20"], 10, 20);
      // final to 100 years
      const age100 = 100 - (start - birth_date) / 31557600000;
      slopes["final"] = slopeCalculation(w["year20"], w["year40"], 20, age100);

      w.slopes = slopes;
    });
  };

  const incomeCalculate = (incomeValues, periods) => {
    // console.log(incomeValues,periods,"test");
    const birth_date = Date.parse(clientData?.clientInfo.personal?.birthDay);
    const birth_dates = new Date(clientData?.clientInfo.personal?.birthDay);

    const incomes = [];
    const data = [];


    // Process term life incomes data
    if (incomeValues && Array.isArray(incomeValues)) {
      incomeValues.forEach((item) => {
        const tc = item;
        if (tc) {
          incomes.push(tc);
        } else {
          console.error("termLife is undefined or null in the item");
        }
      });
    } else {
      console.error("termData is undefined, null, or not an array");
    }


    periods.forEach((element) => {
      const new_element = { age: element };


      incomes.forEach((c) => {
        const start = c.startYear ? Date.parse(c.startYear) : birth_date;

        const ageYear = Number(birth_dates.getFullYear()) + Number(c.endAge);


        const end = ageYear ? Date.parse(ageYear) : birth_date + periods[periods.length - 1] * 31557600000;

        const curent_date = birth_date + element * 31557600000; // Current age as date
        let value = 0;

        if (c.hasOwnProperty("year1")) {
          const policy_age = element - (start - birth_date) / 31557600000;

          if (curent_date >= start) {
            if (policy_age >= 0 && policy_age <= 1) {
              const s = c.slopes[1];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            } else if (policy_age <= 5) {
              const s = c.slopes[5];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            } else if (policy_age <= 10) {
              const s = c.slopes[10];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            } else if (policy_age <= 20) {
              const s = c.slopes[20];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            } else {
              const s = c.slopes["final"];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            }
          }
        } else if (c.hasOwnProperty("annual")) {
          if (curent_date <= end && curent_date >= start) {
            value = c.annual / 1000;
          }
        }

        // Assign the calculated value to the graph element
        new_element[c.name] = value;
      });



      data.push(new_element);
    });


    return data;
  }


  const incomeGraph = incomeCalculate(incomeValues, periods);

  const calculateCoverage = (termData, wholeData, clientData, periods) => {

    const birth_date = Date.parse(clientData?.clientInfo.personal?.birthDay);
    const coverage = [];
    const data = [];

    // Process whole life coverage data
    if (wholeData && Array.isArray(wholeData)) {
      wholeData.forEach((item) => {
        const wc = item.wholeLifeCoverage;
        if (wc) {
          calculateWholeSlopes([wc], birth_date);
          coverage.push(wc);
        } else {
          console.error("wholeLifeCoverage is undefined or null in the item");
        }
      });
    } else {
      // console.error("wholeData is undefined, null, or not an array");
    }

    // Process term life coverage data
    if (termData && Array.isArray(termData)) {
      termData.forEach((item) => {
        const tc = item.termCoverage;
        if (tc) {
          coverage.push(tc);
        } else {
          console.error("termLife is undefined or null in the item");
        }
      });
    } else {
      // console.error("termData is undefined, null, or not an array");
    }

    periods.forEach((element) => {
      const new_element = { age: element };

      coverage.forEach((c) => {
        const start = Date.parse(c.startDate);

        const curent_date = birth_date + element * 31557600000;
        let value = 0;
        if (c.hasOwnProperty("year1")) {
          const policy_age = element - (start - birth_date) / 31557600000;

          if (curent_date >= start) {
            if (policy_age >= 0 && policy_age <= 1) {
              const s = c.slopes[1];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            } else if (policy_age <= 5) {
              const s = c.slopes[5];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            } else if (policy_age <= 10) {
              const s = c.slopes[10];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            } else if (policy_age <= 20) {
              const s = c.slopes[20];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            } else {
              const s = c.slopes["final"];
              value = (policy_age * s["m"] + s["b"]) / 1000;
            }
          }
        } else if (c.hasOwnProperty("deathBenefit")) {
          const end = Date.parse(c.endDate);
          if (curent_date <= end && curent_date >= start) {
            value = c.deathBenefit / 1000;
          }
        }
        new_element[c.carrier] = value;
      });
      data.push(new_element);
    });

    return data;
  };

  const defs = [];
  const areas = [];
  let i = 0;

  const colors = ["#871a1e", "#3c1111"];

  if (liabilityData && liabilityData.length > 0) {
    Object.keys(liabilityData[0]).forEach((k) => {
      if (k !== "age") {
        const color = colors[i];
        const id = "color_" + color;

        defs.push(
          <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0.4} />
          </linearGradient>
        );

        areas.push(
          <Area
            key={k}
            type="monotone"
            dataKey={k}
            stroke={color}
            fillOpacity={1}
            fill={"url(#" + id + ")"}
          />
        );

        i = i + 1;
      }
    });
  } else {
    // console.log("liabilityData is either undefined or empty");
  }

  const dataCt = calculateCoverage(termData, wholeData, clientData, periods);

  const defss = [];
  const areass = [];
  let j = 0;

  const colorss = ["#3d6f94", "#1e3a5a"];

  if (dataCt && dataCt.length > 0) {
    Object.keys(dataCt[0]).forEach((k) => {

      if (k !== "age") {
        const color = colorss[j];
        const id = "color_" + color;

        defss.push(
          <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0.4} />
          </linearGradient>
        );

        areass.push(
          <Area
            key={k}
            type="monotone"
            dataKey={k}
            stroke={color}
            fillOpacity={1}
            fill={"url(#" + id + ")"}
          />
        );

        j = j + 1;
      }
    });
  } else {
    console.log("liabilityData is either undefined or empty");
  }

  const defsss = [];
  const areasss = [];
  let r = 0;


  const colorsss = ["#9ECD95", "#758772"];


  if (incomeGraph && incomeGraph.length > 0) {
    Object.keys(incomeGraph[0]).forEach((m) => {


      if (m !== "age") {
        const color = colorsss[r];
        const id = "color_" + color;


        defsss.push(
          <linearGradient key={id} id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0.4} />
          </linearGradient>
        );


        areasss.push(
          <Area
            key={m}
            type="monotone"
            dataKey={m}
            stroke={color}
            fillOpacity={1}
            fill={"url(#" + id + ")"}
          />
        );


        r = r + 1;
      }
    });
  } else {
    console.log("income is either undefined or empty");
  }


  const calculateSurplus = (coverage, liability, income, periods) => {

    const data = [];
    for (let i = 0; i < periods.length; i++) {
      // Initialize value from income (sum all keys except "age")
      let value = 0;
      for (const [key, val] of Object.entries(income[i])) {
        if (key !== "age") {
          value -= val;
        }
        // console.log(`Key: ${key}, Value: ${val}`);
      }

      // Add coverage values
      for (const [key, val] of Object.entries(coverage[i])) {
        if (key !== "age") {
          value += val;
        }
        // console.log(`Key: ${key}, Value: ${val}, Running Total: ${value}`);
      }

      // Add liability values (if defined)
      if (liability[i]) {
        for (const [key, val] of Object.entries(liability[i])) {
          if (key !== "age") {
            value += val;
            // console.log(`Key: ${key}, Value: ${val}, Running Total: ${value}`);
          }
        }
      }

      // Calculate surplus and deficit
      let surplus = 0;
      let deficit = 0;
      if (value > 0) {
        surplus = value;
      } else {
        deficit = value;
      }

      // Push the result to the data array
      data.push({
        age: periods[i],
        Surplus: surplus,
        Deficit: deficit,
      });
    }


    // console.log(data, "surplus data checking ");

    return data;
  };

  // const income = useMemo(() => calculateIncome(), [clientData, periods]);

  const income = useMemo(
    () => incomeCalculate(incomeValues, periods),
    [incomeValues, periods]
  );


  // const coverage = useMemo(() => calculateCoverage(termData, wholeData, clientData, periods), [termData, wholeData, periods]);
  const coverage = useMemo(() => {
    return calculateCoverage(termData, wholeData, clientData, periods);
  }, [termData, wholeData, clientData, periods]);

  const surplusData = useMemo(
    () => calculateSurplus(coverage, liabilityData, income, periods),
    [coverage, liabilityData, income, periods]
  );

  useEffect(() => {
    const fetchDebtData = async () => {
        try {
            const response = await api.get(`/debt/getAllDebtDetails/${userId}`);
            setDebtData(response.data.debtData);

            // Get birth year from birthday (assuming format is YYYY-MM-DD)
            const birthYear = new Date(clientData?.clientInfo.personal?.birthDay).getFullYear();
            
            // Convert start and end year to age values
            const startAge = Math.min(...response.data.debtData.map(d => parseInt(d.startYear))) - birthYear;
            const endAge = Math.max(...response.data.debtData.map(d => parseInt(d.endYear))) - birthYear;

            console.log("Birth Year:", birthYear);
            console.log("Start Age:", startAge, "End Age:", endAge);

            // Calculate liability using age instead of years
            const liability = calculateLiability(response.data.debtData, startAge, endAge, periods);
            setLiabilityData(liability);

            // Set min/max age for graph
            setMinAge(startAge);
            setMaxAge(endAge);

        } catch (error) {
            console.error("Error fetching debt data:", error);
        }
    };

    if (!debtData) {
        fetchDebtData();
    }
}, [debtData, userId]);


  useEffect(() => {
    const fetchTermData = async () => {
      try {
        const response = await api.get(
          `/coverage/getAllTermCoverageDetails/${userId}`
        );
        const TermData = response.data.coverageData;

        setTermData(TermData);
      } catch (error) {
        console.error("Error fetching term coverage data:", error);
      }
    };

    if (termData === undefined && userId) {
      fetchTermData();
    }
  }, [termData]);

  useEffect(() => {
    const fetchWholeData = async () => {
      try {
        const response = await api.get(
          `/coverage/getAllWholeLifeCoverageDetails/${userId}`
        );
        const coverageData = response.data.coverageData;

        setWholeData(coverageData);
      } catch (error) {
        console.error("Error fetching permanent coverage data:", error);
      }
    };

    if (wholeData === undefined && userId) {
      fetchWholeData();
    }
  }, [wholeData]);

  // Call calculateCoverage when both wholeData and termData are available
  useEffect(() => {
    if (wholeData && termData && clientData) {
      const birth_date = Date.parse(clientData?.clientInfo.personal?.birthDay);
      calculateWholeSlopes(wholeData, birth_date);
      calculateCoverage(termData, wholeData, clientData, periods);
    }
  }, [wholeData, termData, clientData]);

  useEffect(() => {
    if (liabilityData.length > 0) {
      // console.log("Final Liability Data:", liabilityData[0]);
    }
  }, [liabilityData]);

  // if (!debtData || debtData.length === 0) {
  //  return <div>Loading...</div>
  // }
  // else{
  //   <div>No Data Found</div>;
  // }

  if (Array.isArray(surplusData) && surplusData > 0) {
    for (let i = 0; i < surplusData.length; i++) {
      surplusData[i].age = Math.round(surplusData[i].age);
    }
  } else {
    // console.error("surplusData is not an array or is empty");
  }

  useEffect(() => {
    const range = end - start;
    const zoomRange = range / zoomLevel;
    const newStart = Math.max(start, start + (range - zoomRange) / 2);
    const newEnd = Math.min(end, end - (range - zoomRange) / 2);

    setDomain([newStart, newEnd]);
  }, [zoomLevel, start, end]);

  const zoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.5, 3));
  };

  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.5, 1));
  };

  return (
    <>

      <div className="iconDesign" >
        <span className="addcircles plus" onClick={zoomIn} ></span>

        <span className="subtractcircles plus" onClick={zoomOut} ></span>

      </div>

      <div style={{ marginTop: 10 }}>
        <div className="flex">
          <div className="graphOverflow" style={{ flex: 1 }}>
            <div
              id="export"
              style={{
                width: `${100 * zoomLevel}%`,
                overflowX: 'auto',
              }}
              className="zoomGrpah"
            >
              <ResponsiveContainer width="98%" height={surplusHeight}>
                <AreaChart
                  data={surplusData.map((item) => ({ ...item, age: item.age }))}
                  syncId="hlv"
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    <linearGradient id="colorSurplus" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#1A7033" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#1A7033" stopOpacity={0.4} />
                    </linearGradient>
                    <linearGradient
                      id="colorpDeficit"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor="#B41E24" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#B41E24" stopOpacity={0.4} />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="Surplus"
                    stroke="#1A7033"
                    fillOpacity={1}
                    fill="url(#colorSurplus)"
                  />
                  <Area
                    type="monotone"
                    dataKey="Deficit"
                    stroke="#B41E24"
                    fillOpacity={1}
                    fill="url(#colorpDeficit)"
                  />
                  <XAxis
                    dataKey="age"
                    type="number"
                    domain={domain}
                    ticks={ticks.map(Math.round)}
                    tick={{ fontSize: 14 }}
                  />
                  <YAxis tickFormatter={formatInteger} tick={{ fontSize: 14 }} />
                  <Legend
                    layout="vertical"
                    align="right"
                    verticalAlign="top"
                    width={150}
                  />
                  <Tooltip
                    formatter={(value) => Math.round(value).toString()}
                    labelFormatter={(label) =>
                      `Age: ${Math.round(label).toString()}`
                    }
                  />
                  <ReferenceLine
                    x={retireAge}
                    stroke="none"
                    label={{
                      position: "bottom",
                      value: "▲", // Unicode for an upward arrow
                      style: { fill: "#c7595b", fontSize: "25px" },
                      dy: -25,
                    }}
                  />
                </AreaChart>
              </ResponsiveContainer>

              <ResponsiveContainer width="98%" height={cdHeight}>
                <AreaChart
                  data={dataCt.map((item) => ({ ...item, age: item.age }))}
                  syncId="hlv"
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>{defss}</defs>
                  <XAxis
                    dataKey="age"
                    type="number"
                    domain={[start, end]}
                    tickFormatter={formatXAxis}
                    ticks={ticks}
                    tick={{ fontSize: 14 }}
                  />
                  <YAxis tick={{ fontSize: 14 }} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip
                    formatter={(value) => {
                      const formattedValue = Math.round(value).toString();
                      return formattedValue;
                    }}
                    labelFormatter={(label) => {
                      const formattedLabel = Math.round(label).toString();
                      return `Age: ${formattedLabel}`;
                    }}
                  />
                  {areass}
                  <Legend
                    layout="vertical"
                    align="right"
                    verticalAlign="top"
                    width={150}
                  />
                </AreaChart>
              </ResponsiveContainer>

              <ResponsiveContainer width="98%" height={cdHeight}>
                <AreaChart
                  data={incomeGraph.map((item) => ({ ...item, age: item.age }))}
                  syncId="hlv"
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                >
                  <defs>
                    {defsss}
                  </defs>
                  <XAxis
                    dataKey="age"
                    type="number"
                    domain={[start, end]}
                    tickFormatter={formatXAxis}
                    ticks={ticks}
                    tick={{ fontSize: 14 }}
                  />
                  <YAxis tick={{ fontSize: 14 }} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip
                    formatter={(value) => {
                      const formattedValue = Math.round(value).toString();
                      return formattedValue;
                    }}
                    labelFormatter={(label) => {
                      const formattedLabel = Math.round(label).toString();
                      return `Age: ${formattedLabel}`;
                    }}
                  />
                  {areasss}
                  <Legend
                    layout="vertical"
                    align="right"
                    verticalAlign="top"
                    width={150}
                  />
                </AreaChart>
              </ResponsiveContainer>

              <ResponsiveContainer width="98%" height={cdHeight}>
                <AreaChart data={liabilityData.map((item) => ({ ...item, age: item.age }))}
                syncId="hlv"
                  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>

                  <defs>{defs}</defs>

                  <XAxis
                    dataKey="age"
                    type="number"
                    domain={[minAge,maxAge]}
                    tickFormatter={formatXAxis}
                    ticks={ticks}
                    tick={{ fontSize: 14 }}
                  />

                  <YAxis tick={{ fontSize: 14 }} />
                  <CartesianGrid strokeDasharray="3 3" />

                  <Tooltip
                    formatter={(value) => Math.round(value).toString()}
                    labelFormatter={(label) => `Age: ${Math.round(label)}`}
                  />

                  {areas}

                  <ReferenceLine
                    x={retireAge}
                    stroke="none"
                    label={{
                      position: "bottom",
                      value: "▲",
                      style: { fill: "#c7595b", fontSize: "25px" },
                      dy: -25
                    }}
                  />

                  <Legend layout="vertical" align="right" verticalAlign="top" width={150} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default CreateHlv;
